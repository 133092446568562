// mixins for clearfix
// ------------------------
.clearfix() {
  zoom: 1;
  &::before,
  &::after {
    display: table;
    width: 100%;
    content: '';
  }
  &::after {
    clear: both;
  }
}
